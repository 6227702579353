html{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

body{
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  font-family: Roboto, Arial;
  background-color: var(--background-color);
  --background-color: #eee;
  --text-color: #575757;
  --text-color-light: white;
  --app-color: #2196F3; /*500*/
  --app-color-dark: #0D47A1; /*800*/
  --app-color-medium: #1565C0; /*800*/
}

h1,h2,h3,h4,h5,h6{
	color: var(--text-color);
	text-align: center;
}

button{
	border: none;
	background-color: var(--app-color);
	padding: 10px;
	color: var(--text-color-light);
	font-size: 17px;
	font-family: inherit;
	font-weight: bold;
	border-radius: 2px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	cursor: pointer;
	transition: 0.2s ease-out;
}

button:hover:not(:disabled){
	transform: translateY(-3px);
}

button:disabled{
	background-color: gray;
	opacity: 0.7;
	cursor: auto;
}