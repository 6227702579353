.addCableProgress{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 100%;
	max-width: 400px;
	padding-top: 5px;
}

.addCableProgress > svg{
	min-width: 150px;
}

.addCableProgress > h2{
	margin: 0px 10px
}

@media screen and (max-height: 500px) , screen and (max-width: 500px){
	.addCableProgress{
		flex-direction: row;
		position: sticky;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;
		background-color: var(--background-color);
		max-width: none;
	}
}